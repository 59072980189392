import { motion } from 'framer-motion';
import { Link, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, useCallback, useMemo } from 'react';
import tw, { css } from 'twin.macro';
import { Highlight, LinkPlus, PageContainer } from '~/components/common';
import { Layout } from '~/components/common/layout/Layout';
import { SEO } from '~/components/SEO';
import { Frontmatter } from '~/config';
import { usePoemsQuery } from '~/hooks';

// don't add export - uses local gatsby plugin
// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Poems',
  order: 1,
  screenShoot: false,
};

/**
 * Site TOC: Categorized Index of Poem Links
 */
const Page: FC<PageProps> = () => {
  const { poemsByCategory } = usePoemsQuery();

  const listContainer = useCallback(
    (ci) => ({
      hidden: { opacity: 0 },
      show: {
        opacity: 1,
        transition: {
          staggerChildren: 0.13,
          delayChildren: 1.8 + ci,
        },
      },
    }),
    [],
  );

  const listItem = useMemo(
    () => ({
      hidden: { opacity: 0 },
      show: {
        opacity: 1,
      },
    }),
    [],
  );

  return (
    <Layout footer>
      <SEO title={frontmatter.title} path="/poems" />
      <PageContainer tw="flex-wrap">
        {poemsByCategory.map((cat, ci) => (
          <motion.div
            key={cat.slug}
            tw="[max-width:1200px] w-full m-0 pb-4 md:([margin:0 6vw])"
          >
            <motion.div css={bannerWrap}>
              <Highlight
                delay={0.5 + ci}
                duration={0.75}
                bgColor={cat.color}
                color="white"
                fadeText
                fadeTextDelay={0.5}
                fadeTextDuration={0.25}
                customCss={[
                  tw`mb-4 md:(mb-1)`,
                  { maxWidth: '450px', width: '100%', minHeight: 57 },
                ]}
              >
                <h2 /* tw="px-2 font-secondary [line-height:initial] text-2xl md:(text-2xl)" */
                  css={[
                    tw`font-secondary [font-size: 1.75rem] px-2.5 py-1 tracking-tight 
                    md:([font-size: 2rem] -mt-0.5 mb-0 pb-0 tracking-normal)`,
                  ]}
                >
                  {cat.title}
                </h2>
              </Highlight>
              <motion.div
                css={imgWrap}
                variants={listContainer(ci)}
                initial="hidden"
                animate="show"
              >
                {cat.poems.slice(0, 4).map(
                  (poem, i) =>
                    poem.timeline_image_data && (
                      <LinkPlus to={`/poem/${poem.slug}`} key={i}>
                        <motion.div
                          variants={listItem}
                          tw="[max-width:57px] [width:calc(2.5rem + 1.8vw)]"
                        >
                          <GatsbyImage
                            image={poem.timeline_image_data}
                            css={catImageStyle(cat)}
                            alt={`${poem.case_name}`}
                          />
                        </motion.div>
                      </LinkPlus>
                    ),
                )}
              </motion.div>
            </motion.div>
            <motion.ul
              tw="md:([column-count:2] gap-2 pt-2 py-6 ml-0)"
              variants={listContainer(ci)}
              initial="hidden"
              animate="show"
            >
              {cat.poems.map((poem) => (
                <motion.li
                  key={poem.slug}
                  css={[
                    tw`list-none my-2.5 md:(list-disc ml-4 my-1)`,
                    { color: cat.color },
                  ]}
                  variants={listItem}
                >
                  {/* Using Link here instad of LinkPlus to prevent layout shift inside li */}
                  <Link
                    to={`/poem/${poem.slug}`}
                    tw="pr-4 [transition:.2s] text-black hover:text-brand-coral no-underline 
                          text-base md:([font-size: 1.15rem])"
                  >
                    {poem.case_name}
                  </Link>
                </motion.li>
              ))}
            </motion.ul>
          </motion.div>
        ))}
      </PageContainer>
    </Layout>
  );
};

export default Page;

const catImageStyle = (cat) => {
  return [
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.2s ease-in-out;
        width: 100%;
        height: 100%;
        background: ${cat.color};
        opacity: 0.5;
      }
      &:hover {
        &:before {
          background: '';
          opacity: 0;
        }
      }
    `,
  ];
};

const bannerWrap = () => {
  return [
    css`
      display: block;
      @media (min-width: 940px) {
        display: flex;
        align-items: stretch;
      }
    `,
  ];
};

const imgWrap = () => {
  return [
    tw`flex justify-start gap-2 pl-0`,
    css`
      @media (min-width: 940px) {
        padding-left: 0.5rem;
      }
    `,
  ];
};
