import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { getShortPath } from '../utils/linkUtils';

type SEOProps = {
  title?: string;
  description?: string;
  keywords?: string[];
  path: string;
  permalink?: string;
};

export const SEO: FC<SEOProps> = ({
  title,
  description,
  keywords = [],
  path,
  permalink,
}) => {
  const { site, social } = useStaticQuery(
    graphql`
      query {
        social: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/strings/social/" } }
        ) {
          edges {
            node {
              frontmatter {
                og_description
                og_image {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
          }
        }
        site: site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  );

  const initialKeywords = useRef(keywords); // make non-reactive, memoizable since it will never change

  const { metaTitle, meta } = useMemo(() => {
    const response = site.siteMetadata;
    const socialResponse = social.edges[0].node.frontmatter;
    const metaTitle = title || response.title;
    const metaDescription = description || socialResponse.og_description;
    const metaAuthor = response.author || '';
    const ogImage =
      response.siteUrl + socialResponse.og_image.childImageSharp.original.src;

    // Gatsby createPage is passing '/*' for poem page paths in prod, so using permalink for those
    const ogUrl =
      permalink ||
      getShortPath(response.siteUrl) +
        (path === '/' ? '' : getShortPath(path)! + '/');

    const meta = [
      {
        name: 'description',
        content: metaDescription,
      },
      {
        property: 'og:title',
        content: metaTitle,
      },
      {
        property: 'og:description',
        content: metaDescription,
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        property: `og:image`,
        content: ogImage,
      },
      {
        property: `og:url`,
        content: ogUrl,
      },
      {
        name: 'twitter:card',
        content: 'summary',
      },
      {
        name: 'twitter:creator',
        content: metaAuthor,
      },
      {
        name: 'twitter:title',
        content: title,
      },
      {
        name: 'twitter:description',
        content: metaDescription,
      },
    ];

    if (initialKeywords.current.length > 0) {
      meta.push({
        name: 'keywords',
        content: initialKeywords.current.join(', '),
      });
    }

    return { metaTitle, meta };
  }, [description, path, permalink, site, social, title]);

  return (
    <Helmet htmlAttributes={{ lang: 'en' }} title={metaTitle} meta={meta}>
      {/* Scripts here - browser-only scripts should be gated with !isSSG && !isNetlify */}
    </Helmet>
  );
};
